import React, {useEffect, useState} from "react";
import { Page, Text, View, Document,PDFViewer, Table, G } from "@react-pdf/renderer";
import axios from '../services/axios'
import moment from 'moment';
import { useParams } from 'react-router-dom';

const TableDocument = () => {
  const [credits, setCredits] = useState([]);
  const [dettes, setDettes] = useState([]);
  const {idclient, iddevise} = useParams();
  const [amountDette, setAmountDette] = useState(0);
  const [amountCredit, setAmountCredit] = useState(0);
  useEffect(() => {
    getCreditsAndDettes()
  }, []);

  const getCreditsAndDettes = async() => {
    let response = {};
    if (iddevise) {
      response = await axios.get(`/credits/rapportpdf/by-devise/undefined/${iddevise}`)
    }
    else if (idclient) {
      response = await axios.get(`/credits/rapportpdf/by-client/${idclient}`)
    }
    else if (idclient && iddevise) {
      response = await axios.get(`/credits/rapportpdf/${idclient}/${iddevise}`)
    }

    else {
      response = await axios.get(`/operations/credits/rapport-pdf`)
    }
    const { credits, dettes, amountCredit, amountDette} = response.data;

    setCredits(credits);
    setDettes(dettes);
    setAmountCredit(amountCredit);
    setAmountDette(amountDette);
    

  }
  const createTableHeader = () => {
    return (
      <View style={tableRowStyle} fixed>

        <View style={firstTableColHeaderStyle}>
          <Text style={tableCellHeaderStyle}>N°</Text>
        </View>

        <View style={secondTableColHeaderStyle}>
          <Text style={tableCellHeaderStyle}>Clients</Text>
        </View>

        <View style={tableColHeaderStyle}>
          <Text style={tableCellHeaderStyle}>Montant</Text>
        </View>
      </View>
    );
  };

  const createTableRow = ( item, index ) => {
    return (
      <View key={index} style={tableRowStyle}>
              <View style={ firstTableColStyle }>
                <Text style={tableCellStyleCli}> { index + 1}</Text>
              </View>
              <View style={ tableColStyle }>
                <Text style={tableCellStyleCli}> { item.prenom } { item.nom } { item.telephone }</Text>
              </View>
              <View style={ tableColStyle2 }>
                <Text style={tableCellStyle}> { `${item.solde}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') } { item.code }</Text>
              </View>

      </View>
    );
  };

  return (
    <PDFViewer style={viewerStyle}>
      <Document>
        <Page
          style={pageStyle}
          size="A4"
          orientation="portrait">

          <View style={tableStyle}>
          <Text style={{ marginBottom: 15 , marginTop: 15, textAlign: "center"}}> La liste des dettes clients du : { moment().format("DD-MM-YYYY hh:mm:ss")}</Text>
            {createTableHeader()}
            {
              dettes.map((dette, index) => {
                return (
                  <>
                  { createTableRow(dette, index) }
                  </>
                )
              })
            }
          </View>
          <View style={styleTotalAmountDette}>
            {
                Object.entries(amountDette).map(([k, v]) => <Text style={totalAmount}> { `${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " " + k  }</Text>)
            }
          </View>
         

          {/* pour l'affichage des dettes */}
          
          <View break style={tableStyle}>
          <Text style={{ marginBottom: 15 , marginTop: 15, textAlign: "center"}}> La liste des credits clients du :  {  moment().format("DD-MM-YYYY hh:mm:ss")}</Text>

            {createTableHeader()}
            {
              credits.map((credit, index) => {
                return (
                  <>
                  { createTableRow(credit, index) }
                  </>
                )
              })
            }
             
          </View>

          <View style={styleTotalAmountCredit}>
             {
                Object.entries(amountCredit).map(([k, v]) => <Text style={totalAmount}> { `${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " " + k  }</Text>)
             }
    
          </View>

        </Page>
      </Document>
    </PDFViewer>
  );

};

const pageStyle = {
  paddingTop: 16,
  paddingHorizontal: 40,
  paddingBottom: 56
};

const tableStyle = {
  display: "table",
  width: "auto"
};

const tableRowStyle = {
  flexDirection: "row"
};

const firstTableColHeaderStyle = {
  width: "20%",
  borderStyle: "solid",
  borderColor: "#000",
  borderBottomColor: "#000",
  borderWidth: 1,
  backgroundColor: "#bdbdbd"
};
const secondTableColHeaderStyle = {
  width: "160%",
  borderStyle: "solid",
  borderColor: "#000",
  borderBottomColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  backgroundColor: "#bdbdbd"
};

const tableColHeaderStyle = {
  width: "80%",
  borderStyle: "solid",
  borderColor: "#000",
  borderBottomColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  backgroundColor: "#bdbdbd"
};

const firstTableColStyle = {
  width: "10%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 1,
  borderTopWidth: 0
};

const tableColStyle = {
  width: "80%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 0
};
const tableColStyle2 = {
  width: "40%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 0
};

const tableCellHeaderStyle = {
  textAlign: "center",
  margin: 4,
  fontSize: 12,
  fontWeight: "bold"
};

const tableCellStyle = {
  textAlign: "right",
  margin: 5,
  fontSize: 12,
  fontWeight: "bold"

};
const tableCellStyleCli = {
  textAlign: "left",
  margin: 5,
  fontSize: 12
};
const viewerStyle = {
  display: "block",
  margin: "0 auto",
  width: "70vw",
  height: "90vh"
};
const styleTotalAmountCredit = {
  marginTop: 15,
  padding: 10,
  backgroundColor: '#7CB98A'
}

const styleTotalAmountDette = {
  marginTop: 20,
  padding: 10,
  backgroundColor: '#D35871'
}

const totalAmount = {
  padding: 5
}

export default TableDocument;