import React from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'
import Logo from './assets/company.png'
import Compagny from './assets/logo.png'
import Forms from './components/form'
import './assets/css/style.css'

interface IProps {
    onSubmit: (values: any) => void,
    isLoading: boolean,
    isRedirect?: boolean
}
export default function Index({ onSubmit, isLoading }: IProps) {

    const [isRedirect, setRedirect] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const history = useHistory()
    const onFinish = (values: any) => {
        setLoading(true)
        onSubmit(values)
    };

    return (
        <div className="content">
            
            <section className="h-100 gradient-form" style={{backgroundColor: "#eee"}}>
  <div className="container py-5 h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col-xl-10">
        <div className="card rounded-3 text-black">
          <div className="row g-0">
            <div className="col-lg-6">
              <div className="card-body p-md-5 mx-md-4">

                <div className="text-center">
                  <img src={Logo}
                    style={{width: "185px"}} alt="logo"/>
                  <h5 className=" mb-5 ">Veuillez vous connecter à votre compte</h5>
                </div>

                  <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                        >
                                    <Form.Item
                                        name="username"
                                        rules={[{ required: true, message: 'Veuillez saisir votre nom d\'utilisateur !' }]}
                                    >
                                        <Input prefix={<UserOutlined className="site-form-item-icon" />}
                                            placeholder="Nom d'utilisateur" />
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        rules={[{ required: true, message: 'Veuillez saisir votre mot de passe !' }]}
                                    >
                                        <Input
                                            prefix={<LockOutlined className="site-form-item-icon" />}
                                            type="password"
                                            placeholder="Mot de passe"
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Form.Item name="remember" valuePropName="checked" noStyle>
                                            <Checkbox>Remember me</Checkbox>
                                        </Form.Item>

                                    </Form.Item>

                                    <Form.Item>
                                        <Button 
                                            style={{ backgroundColor: "#155491"}} 
                                            loading={isLoading} type="primary" 
                                            htmlType="submit" 
                                            className="login-form-button btn btn-primary btn-block fa-lg gradient-custom-2 mb-3">
                                            Se connecter
                                        </Button>
                                    </Form.Item>
                                </Form>


              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
              <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                <p className=" mb-0">
                     Toutes vos opérations de change (transfert) dans votre main en toute simplicité. 
                </p>
              </div>
            
            </div>
           
              <div className="row px-3">
                 <small className="ml-4 ml-sm-5 mb-2">
                  
                  <span className='ml-2'>
                     Copyright &copy; 2023. All rights reserved. Created by 
                      <a className='ml-1 mr-1' style={{color: "#156FC2"}} target="_blank" href="https://bimapgroup.com"> 
                         Bimap group
                      </a> 
                  </span> 
                  <span className='ml-5'>
                    version 1.2 
                  </span>
                </small>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
{
                isRedirect && history.push({ pathname: '/', state: { isAuth: true } })
            }
        </div>
    );
}
